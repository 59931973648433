<template>
  <div class="crypto-address-table-container">
    <CCard class="crypto-address-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{item, index}">
          <td>{{ index + 1 + (activePage - 1) * limit }}</td>
        </template>

        <template #wallet_name="{item}">
          <td>
            <span :title="item.wallet_name">
              {{ item.wallet_name | truncate(20) }}
            </span>
          </td>
        </template>

        <template #first_name="{item}">
          <td>{{ item.first_name || '-' }}</td>
        </template>

        <template #last_name="{item}">
          <td>{{ item.last_name || '-' }}</td>
        </template>

        <template #email="{item}">
          <td>{{ item.member_email }}</td>
        </template>

        <template #reward_address="{item}">
          <td>
            <CIcon v-if="item.is_reward_address" class="reward-icon" name="cil-check" />
          </td>
        </template>

        <template #membership_type="{item}">
          <td>
            <StakingBadge class="no-border" type="membership-type" :text="item.membership_type" />
          </td>
        </template>

        <template #address="{item}">
          <td>
            <StakingExplorerLink type="address" :currencySymbol="item.platform" :value="item.wallet_address" />
          </td>
        </template>

        <template #created_at="{item}">
          <td>{{ item.created_at | moment(dateFormat) }}</td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StakingBadge from '@/components/StakingBadge';
import { Constants } from '@/constants';
import StakingExplorerLink from '@/components/StakingExplorerLink';

export default {
  name: 'CryptoAddressListTable',
  components: {
    StakingBadge,
    StakingExplorerLink,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: '#',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_NUMBER_COL'),
        },
        {
          key: 'wallet_name',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_WALLET_NAME_COL'),
        },
        {
          key: 'platform',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_PLATFORM_COL'),
        },
        {
          key: 'address',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_ADDRESS_COL'),
          _style: ('min-width: 480px'),
        },
        {
          key: 'reward_address',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_REWARD_ADDRESS_COL'),
          _style: 'min-width: 50px; text-align: left;',
        },
        {
          key: 'first_name',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_FIRST_NAME_COL'),
        },
        {
          key: 'last_name',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_LAST_NAME_COL'),
        },
        {
          key: 'email',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_EMAIL_COL'),
        },
        {
          key: 'membership_type',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_MEMBERSHIP_TYPE_COL'),
        },
        {
          key: 'kyc_level',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_KYC_LEVEL_COL'),
        },
        {
          key: 'created_at',
          label: this.$t('CRYPTO_ADDRESS_LIST_TABLE_COMPONENT_CREATED_AT_COL'),
          _style: 'min-width: 150px; text-align: left;',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.crypto-address-table-container {
  > .description {
    height: 16px;
    color: #8a93a2;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
  }

  .crypto-address-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }

      .reward-icon {
        color: #5da30b;
      }

      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;

        &.btn-edit {
          outline: none;
        }
      }
    }
  }
}
</style>
