<template>
  <div class="crypto-address-list-container">
    <CCard class="staking-card">
      <CCardHeader>
        <CRow>
          <CCol md="3" sm="12">
            <h1>{{$t("CRYPTO_ADDRESS_LIST_PAGE_TITLE")}}</h1>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchCryptoAddressesHeader
          :onSearch="onSearch"
          :paidMemberships="paidMemberships"
          :platformList="platformList"
          :downloadCSV="downloadCSV"
          :isAllowedDownloadCSV="isAllowedDownloadCSV"
        />

        <CryptoAddressListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
        />

        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';
import SearchCryptoAddressesHeader from '@/components/CryptoAddress/SearchCryptoAddressesHeader';
import CryptoAddressListTable from '@/components/CryptoAddress/CryptoAddressListTable';
import StakingPagination from '@/components/StakingPagination';
import { endpoints, env } from '@/constants';
import { mapState, mapActions, mapGetters } from 'vuex';
import responseHelper from '@/utils/response-helper';
import { MembershipOrderStatus, PaymentType } from '@/enums';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import stringHelper from '@/utils/string-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'CryptoAddressList',
  components: {
    CryptoAddressListTable,
    SearchCryptoAddressesHeader,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      paidMemberships: [],
      platformList: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      isAllowedDownloadCSV: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();
    await Promise.all([this.getMemberships(), this.getPlatforms()]);
    this.isLoading = false;

    this.onSearch();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'EXPORT_CRYPTO_ADDRESSES_CSV') {
            this.isAllowedDownloadCSV = true;
          }
        });
      }
    },
    async getMemberships() {
      const result = await this.$http.get(endpoints.getMembershipTypes);
      let paidMemberships = result.data
        .filter(item => item.is_enabled)
        .map(item => {
          return {
            value: item.id,
            label: item.name,
            currency_symbol: item.currency_symbol,
            isEnabled: item.is_enabled,
            displayOrder: item.display_order || 0,
          };
        });

      paidMemberships = _.sortBy(paidMemberships, item => {
        return item.display_order || 0;
      });

      this.paidMemberships = stringHelper.translateTextForDropdownList(paidMemberships, 'MEMBERSHIP_TYPE');
    },
    async getPlatforms() {
      try {
        const params = {
          offset: 0,
          limit: 100000000,
        };
        const result = await this.$http.get(endpoints.getPlatforms, { params });
        this.platformList = _.sortBy(result.data.items, 'order_index').map(item => {
          const currencySymbol = item.platform === item.symbol ? item.platform : item.symbol;

          return {
            ...item,
            value: currencySymbol,
            label: currencySymbol,
          };
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getCryptoAddresses(data) {
      data = data || {};

      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          membership_type_id: filterData.membershipType,
          platform: filterData.platform,
          email: filterData.email,
          address: filterData.address,
          is_reward_address: filterData.isRewardAddress,
        };
        const result = await this.$http.get(endpoints.getCryptoAddresses, { params });

        this.items = result.data.items || [];
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('ORDER_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getCryptoAddresses();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getCryptoAddresses();
    },
    async onSearch(searchData) {
      const data = searchData || sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterCryptoAddresses);
      this.activePage = 1;

      this.getCryptoAddresses(data);
    },
    async downloadCSV(data) {
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          membership_type_id: filterData.membershipType,
          platform: filterData.platform,
          email: filterData.email,
          address: filterData.address,
          is_reward_address: filterData.isRewardAddress,
          time_offset: new Date().getTimezoneOffset(),
        };
        const result = await this.$http.get(endpoints.getCryptoAddressesCSV, { params });
        const data = result;
        const fileUrl = 'crypto-addresses.csv';
        const contentType = 'application/octet-stream';
        const file = new Blob([data], { type: contentType });
        saveAs(file, fileUrl);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CLAIM_REQUEST_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.crypto-address-list-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
