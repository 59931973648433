var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crypto-address-table-container"},[_c('CCard',{staticClass:"crypto-address-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage - 1) * _vm.limit))])]}},{key:"wallet_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{attrs:{"title":item.wallet_name}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.wallet_name,20))+" ")])])]}},{key:"first_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.first_name || '-'))])]}},{key:"last_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.last_name || '-'))])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.member_email))])]}},{key:"reward_address",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.is_reward_address)?_c('CIcon',{staticClass:"reward-icon",attrs:{"name":"cil-check"}}):_vm._e()],1)]}},{key:"membership_type",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{staticClass:"no-border",attrs:{"type":"membership-type","text":item.membership_type}})],1)]}},{key:"address",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingExplorerLink',{attrs:{"type":"address","currencySymbol":item.platform,"value":item.wallet_address}})],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,_vm.dateFormat)))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }