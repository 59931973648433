<template>
  <div class="search-crypto-address-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_PLATFORM_FIELD_NAME"
            v-slot="{ errors }"
            rules
          >
            <div role="group" class="form-group">
              <label class>{{$t("SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_PLATFORM_LABEL")}}</label>
              <select v-model="searchData.platform" class="form-control">
                <option value>{{$t("SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_OPTION_ALL")}}</option>
                <option
                  v-for="item in platformList"
                  :key="item.value"
                  :value="item.value"
                >{{item.label}}</option>
              </select>
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{errors[0]}}</span>
            </span>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_ADDRESS_FIELD_NAME"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_ADDRESS_LABEL')"
              type="text"
              v-model="searchData.address"
              class="form-group"
              @keyup.enter="search()"
            />
            <span class="error-msg-wrap">
              <span class="error-msg">{{errors[0]}}</span>
            </span>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_EMAIL_FIELD_NAME"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_EMAIL_LABEL')"
              type="text"
              v-model="searchData.email"
              class="form-group"
              @keyup.enter="search()"
            />
            <span class="error-msg-wrap">
              <span class="error-msg">{{errors[0]}}</span>
            </span>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_MEMBERSHIP_FIELD_NAME"
            v-slot="{ errors }"
            rules
          >
            <div role="group" class="form-group">
              <label class>{{$t("SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_MEMBERSHIP_LABEL")}}</label>
              <select v-model="searchData.membershipType" class="form-control">
                <option value>{{$t('SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_OPTION_ALL')}}</option>
                <option
                  v-for="item in paidMemberships"
                  :key="item.value"
                  :value="item.value"
                >{{item.label}}</option>
              </select>
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{errors[0]}}</span>
            </span>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_REWARD_ADDRESS_FIELD_NAME"
            v-slot="{ errors }"
            rules
          >
            <div role="group" class="form-group">
              <label class>{{$t("SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_REWARD_ADDRESS_LABEL")}}</label>
              <div class="btn-switch-wrapper">
                <label class="btn-switch">
                  <input type="checkbox" class="checkbox" v-model="searchData.isRewardAddress" />
                  <span class="check-silder"></span>
                </label>
              </div>
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{errors[0]}}</span>
            </span>
          </ValidationProvider>
        </CCol>
      </CRow>
    </ValidationObserver>

    <CRow class="mt-2 form-actions">
      <CCol md="3" sm="12">
        <CButton
          class="btn btn-download-csv"
          v-if="isAllowedDownloadCSV"
          @click="onDownloadCSV()"
        >{{$t("SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_DOWNLOAD_CSV_BUTTON")}}</CButton>
      </CCol>

      <CCol md="3"></CCol>

      <CCol md="3" sm="12">
        <CButton
          class="btn btn-reset"
          @click="resetForm()"
        >{{$t('SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_BUTTON_RESET')}}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton
          class="btn btn-search"
          @click="search()"
        >{{$t("SEARCH_CRYPTO_ADDRESS_HEADER_COMPONENT_BUTTON_SEARCH")}}</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from 'moment';
import { Constants } from '@/constants';
import DateRangePicker from 'vue2-daterange-picker';
import stringHelper from '@/utils/string-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';

export default {
  name: 'SearchCryptoAddressesHeader',
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterCryptoAddresses) || this.getDefaultValues(),
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    paidMemberships: {
      type: Array,
      default: () => [],
    },
    platformList: {
      type: Array,
      default: () => [],
    },
    downloadCSV: {
      type: Function,
      default: null,
    },
    isAllowedDownloadCSV: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getDefaultValues() {
      return {
        isRewardAddress: false,
        platform: '',
        membershipType: '',
        email: '',
        address: '',
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();

      this.search();
    },
    async search() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterCryptoAddresses, this.searchData);
        const searchData = {
          ...this.searchData,
        };

        this.onSearch(searchData);
      });
    },
    async onDownloadCSV() {
      const searchData = {
        ...this.searchData,
      };

      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.downloadCSV(searchData);
      });
    },
  },
};
</script>

<style lang="scss">
.search-crypto-address-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
      }

      &.btn-reset {
        color: #1a2958;
        background-color: #fff;
      }
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>
